<script>
import { getCustomerList } from 'api/customerList'
import { getServiceList } from 'api/beijing28'
export default {
    name:"Service",
    components:{
        Header: () => import('components/base/Header'),

        'mt-header': app.Mint.Header,
        'mt-button': app.Mint.Button,
    },
    data() {
        return {
            serviceList:null,
            list: [],
            header: {
                mainTitle:getWord('online_service2'),
            },
        }
    },
    methods: {
        getList(type) {
            var that = this
            getCustomerList(type).then(result=>{
                this.list = result.data.result
                if (!result.data.result.length) {
                    app.Mint.Toast({
                        message: getWord('maintaining2'),
                        position: 'top'
                    });
                    return false
                }else {
                    this.$router.push('/customerList/onLineList?type='+type)
                }
            })            
        },
        filterIcon(type){
            switch(type){
                case 1:
                    return require('@@/assets/'+this._TEMPLATE+'/images/service/icons/qa.png')
                    break;
                case 2:
                    return require('@@/assets/'+this._TEMPLATE+'/images/service/icons/qq.png')
                    break;
                case 3:
                    return require('@@/assets/'+this._TEMPLATE+'/images/service/icons/wx.png')
                    break;
                case 4:
                    return require('@@/assets/'+this._TEMPLATE+'/images/service/icons/wa.png')
                    break;
                default:
                    break;
            }
        },
        filterTitle(type){
            switch(type){
                case 1:
                    return getWord('online_service')
                    break;
                case 2:
                    return getWord(['social_app1','online_service2'])
                    break;
                case 3:
                    return getWord(['social_app2','online_service2'])
                    break;
                case 4:
                    return getWord(['social_app4','online_service2'])
                    break;
                default:
                    break;
            }
        },
    },
    created() {
        getServiceList().then(result=>{
            if (result.data.code==='SUCCESS') {
                this.serviceList=result.data.result;
            }else {

            }
        })

        this.$root.$emit('closeBottomTab', false)
    },
    beforeRouteLeave(to, from, next){
        this.$root.$emit('closeBottomTab', true)
        next();
    },
};
</script>
<template>
    <section id="service" :class="_TEMPLATE">
        <Header v-if="_TEMPLATE==='template-3'"
            :mainTitle="header.mainTitle"
            :backHome=true
            :backButton=true
        />
        <div class="inner">
            <div class="customer_bg">
                <p id="kefu">{{ getWord('service_centre') }}</p>
                <p id="kfzx">Customer service center</p>
            </div>
            <div class="customer_bg2">
                <ul>
                    <li v-for="(item,index) in serviceList" @click="getList(item.type)" :key="index">
                        <div class="inner">
                            <img :src="item.img" class="img1"  v-if="_TEMPLATE==='template-1'"/>
                            <img :src="filterIcon(item.type)" class="img1"  v-if="_TEMPLATE==='template-3'"/>
                            <span>{{ filterTitle(item.type) }}</span>
                            <img src="@@/assets/images2/customer/rightButton.png" class="img2" />
                            <i class="iconfont icon-back" v-if="_TEMPLATE==='template-3'"></i>
                        </div>
                    </li>
                    <!-- <li @click="isHaveCustomer('1')">
                        <div class="inner">
                            <img :src="require('@@/assets/'+_TEMPLATE+'/images/service/icons/qa.png')" class="img1" />
                            <span>{{ getWord('online_service') }}</span>
                            <img src="@@/assets/images2/customer/rightButton.png" class="img2" />
                            <i class="iconfont icon-back" v-if="_TEMPLATE==='template-3'"></i>
                        </div>
                    </li>
                    <li @click="isHaveCustomer('2')">
                        <div class="inner">
                            <img :src="require('@@/assets/'+_TEMPLATE+'/images/service/icons/qq.png')" class="img1" />
                            <span>{{ getWord(['social_app1','online_service2']) }}</span>
                            <img src="@@/assets/images2/customer/rightButton.png" class="img2" />
                            <i class="iconfont icon-back" v-if="_TEMPLATE==='template-3'"></i>
                        </div>
                    </li>
                    <li @click="isHaveCustomer('3')">
                        <div class="inner">
                            <img :src="require('@@/assets/'+_TEMPLATE+'/images/service/icons/wx.png')" class="img1" />
                            <span>{{ getWord(['social_app2','online_service2']) }}</span>
                            <img src="@@/assets/images2/customer/rightButton.png" class="img2" />
                            <i class="iconfont icon-back" v-if="_TEMPLATE==='template-3'"></i>
                        </div>
                    </li>
                    <li style="border-bottom:0" @click="isHaveCustomer('4')">
                        <div class="inner">
                            <img :src="require('@@/assets/'+_TEMPLATE+'/images/service/icons/wa.png')" class="img1" />
                            <span>{{ getWord(['social_app4','online_service2']) }}</span>
                            <img src="@@/assets/images2/customer/rightButton.png" class="img2" />
                            <i class="iconfont icon-back" v-if="_TEMPLATE==='template-3'"></i>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>
    </section>
</template>
<style lang='scss' scoped>
#service {
    background-color: #f3f3f4;
    height: 100%;

    &.template-3 {

        .inner {

            .customer_bg {
                top:.88rem;
                text-align: left;
                padding:.5rem 1rem 1rem;
                background-image: url('/static/assets/template-3/images/service/bg/bg.png');
                background-size: cover;
                background-repeat: no-repeat;
                height: auto;
                width: 100%;
                float: none;

                #kefu {
                    margin-top: 0;
                    color: #ffffff;
                }

                #kfzx {
                    color: #ffffff;
                    text-transform: uppercase;
                    font-size: .28rem;
                }
            }

            .customer_bg2 {
                margin: 1.75rem auto;
                width: auto;
                box-shadow: none;
                padding:0 .5rem;
                background-image: none;
                background-color: transparent;

                ul {
                    border-radius: 6px;
                    overflow: hidden;

                    li {
                        margin-left: 0;
                        width: auto;
                        float: none;
                        padding: .25rem;
                        background-color: #ffffff;
                        position:relative;

                        .icon-back {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%) rotateZ(180deg);
                            margin-top: -3px;
                            right: .25rem;
                        }

                        .img2 {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.myposition {
    background-color: rgba(239, 239, 239, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.customer_bg {
    text-align: center;
    position: absolute;
    top: 0;
    background-image: url('/static/assets/images2/customer/backBig.png');
    width: 100%;
    height: 3.48rem;


    #kefu {
        margin-top: 0.82rem;
        font-size: 0.36rem;
        color: #ffffff;
    }

    #kfzx {
        margin-top: 0.17rem;
        font-size: 0.36rem;
        color: #ffffff;
    }
}

.customer_bg2 {
    border-radius: 5px;
    box-shadow: 2px 2px 2px #ccc;
    position: relative;
    background-size: 100%;
    margin: 2.32rem auto;
    width: 95%;
    border-radius: 0.1rem;
    background: #fff;

    span {
        font-size: 0.3rem;
    }


}

.customer_bg2 ul {

    margin: 0;
    padding: 0;
    width: 100%;
}

.customer_bg2 ul li {
    list-style: none;

    padding: 0.3rem .15rem;


}

.customer_bg2 ul li .img1 {
    width: 0.7rem;
    height: 0.7rem;
    float: left;
    margin: 0 0.65rem 0 0;
}

.customer_bg2 ul li .img2 {
    width: 0.21rem;
    height: 0.39rem;
    margin: 0.15rem 0 0 0;
    float: right;
}

.customer_bg2 ul li span {
    display: inline-block;
    height: 0.7rem;
    line-height: 0.7rem;
}

ul li {
    border-bottom: 0.01rem solid rgba(187, 187, 187, 1);
}
</style>